/** @jsx jsx */
import { graphql, navigate } from "gatsby";
import ReactPaginate from "react-paginate";
import { Box, Container, Grid, jsx } from "theme-ui";
import BlogCard from "../components/BlogCard";
import HexagonList from "../components/HexagonList";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const BlogListTemplate = ({ data, pageContext }) => {
  const handlePageClick = (e) => {
    const pageSelected = e.selected;
    const url = pageSelected === 0 ? "/blog" : `/blog/${pageSelected + 1}`;
    navigate(url);
  };

  return (
    <Layout
      contactBackground={data.ContactBackground.childImageSharp.gatsbyImageData}
      headerBannerText="blogs"
      headerBgImage={data.HeroImage.childImageSharp.gatsbyImageData}
      headerFancyBanner={false}
      headerSmallHero={true}
    >
      <Seo title="Blog" />

      <Container
        sx={{
          marginBlock: 10,
        }}
      >
        {/* <Flex
          as="ul"
          sx={{
            flexWrap: "wrap",
            listStyleType: "none",
            paddingInlineStart: "0",
          }}
        >
          <li>
            <Link
              to="/blog"
              aria-selected={true}
              sx={{
                variant: "buttons.primary",
                margin: "10px",
              }}
            >
              View all
            </Link>
          </li>
          {data.allStrapiCategory.edges.map((cat, index) => (
            <li key={`category-${index}`}>
              <Link
                to={`/blog/${cat.node.slug}`}
                sx={{
                  variant: "buttons.primary",
                  margin: "10px",
                }}
              >
                {cat.node.name}
              </Link>
            </li>
          ))}
        </Flex> */}
      </Container>

      <Box
        as="section"
        sx={{
          marginBlockEnd: 9,
          marginBlockStart: 10,
        }}
      >
        <Container>
          <Grid
            sx={{
              gridTemplateColumns: ["1fr", "repeat(2, 1fr)", "repeat(3, 1fr)"],
              gridColumnGap: "20px",
              gridRowGap: "50px",
            }}
          >
            {data.Articles.edges.map((blog, index) => {
              const {
                node: {
                  name,
                  childMarkdownRemark: {
                    frontmatter: { cover, coverAlt, title },
                    excerpt,
                  },
                },
              } = blog;
              return (
                <BlogCard
                  key={`blog-${index}`}
                  href={`/blog/${name.toLowerCase()}`}
                  image={cover}
                  imageAlt={coverAlt}
                  //   categories={blog.node.categories}
                  title={title}
                  description={excerpt}
                />
              );
            })}
          </Grid>

          <ReactPaginate
            breakLabel="..."
            forcePage={true}
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageContext.numPages}
            previousLabel="<"
            renderOnZeroPageCount={null}
            sx={{
              marginBlock: 8,
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              listStyleType: "none",
              paddingInlineStart: "0",
              a: {
                cursor: "pointer",
                color: "secondary",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "25px",
                marginInline: 2,
                ":hover": {
                  textDecoration: "underline",
                },
              },
            }}
          />
        </Container>
      </Box>

      <HexagonList
        bannerText="roof repair made easy."
        linkText="Get Started"
        linkURL="/contact"
      />
    </Layout>
  );
};

export const query = graphql`
  query BlogPageQuery($skip: Int!, $limit: Int!) {
    HeroImage: file(relativePath: { eq: "blog-hero.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    RoofTopsIcon: file(relativePath: { eq: "roof-tops.svg" }) {
      id
      publicURL
    }
    ContactBackground: file(relativePath: { eq: "footer-bg.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    Articles: allFile(
      filter: {
        sourceInstanceName: { eq: "posts" }
        childMarkdownRemark: { frontmatter: { published: { eq: true } } }
      }
      sort: {
        fields: childrenMarkdownRemark___frontmatter___publishedDate
        order: DESC
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          name
          childMarkdownRemark {
            excerpt
            frontmatter {
              title
              cover
              coverAlt
              publishedDate
            }
          }
        }
      }
    }
  }
`;

export default BlogListTemplate;
