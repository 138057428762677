/** @jsx jsx */
import { Link } from "gatsby";
import { Box, Heading, Paragraph, Text, jsx } from "theme-ui";

const BlogCard = ({
  className,
  image,
  imageAlt = "",
  description,
  title,
  href,
}) => {
  return (
    <Box
      className={className}
      as="article"
      sx={{
        boxShadow: "0px 0px 20px 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      {image && (
        <img
          src={image}
          alt={imageAlt}
          loading="lazy"
          width={1200}
          height={500}
          sx={{
            objectFit: "cover",
            height: "263px",
            width: "100%",
            verticalAlign: "middle",
          }}
        />
      )}
      <Box
        sx={{
          backgroundColor: "white",
          paddingBlock: 3,
          paddingInline: 5,
        }}
      >
        <Heading
          as="h1"
          sx={{
            color: "text",
            fontFamily: "body",
            fontSize: "5",
            fontWeight: "heading",
            lineHeight: "33px",
            marginBlockStart: 3,
            textTransform: "uppercase",
          }}
        >
          {title}
        </Heading>

        <Paragraph sx={{ marginBlockEnd: 5, marginBlockStart: 1, padding: 0 }}>
          {description}
        </Paragraph>

        <Link
          to={href}
          sx={{
            alignItems: "center",
            color: "secondary",
            cursor: "pointer",
            display: "inline-flex",
            textDecoration: "none",
          }}
        >
          <Text sx={{ fontWeight: "bold", marginInlineEnd: 2 }}>Read More</Text>
          <svg
            role="img"
            aria-hidden={true}
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L7 7L0.999999 13" stroke="#1A6FC4" strokeWidth="2" />
          </svg>
        </Link>
      </Box>
    </Box>
  );
};

export default BlogCard;
